<template>
   <div class="contain">
      <div class="title">隐私政策</div>
      <div class="prag">
         <div class="mb-12">
            <strong class="str">
               Uptick
            </strong>
            （以下或称“我们”）注重保护用户个人信息及个人隐私。本隐私政策包含我们收集、处理、存储、使用和保护用户（“您”）个人信息（以下或称“信息”）的有
            关条款。我们希望通过本隐私政策向您清晰地介绍我们对您个人信息的处理方式，因此我们建议您完整地阅读本隐私政策，以帮助您了解维护自己隐私权的方式。如 您对本隐私政策有任何疑问，您可以通过我们公布的联系方式与我们联系。
            <strong class="str">
               如果您不同意本隐私政策的任何内容，您应立即停止使用我们服务。当您使用我们提供的任一服务时，即表示您已同意我们按照本隐私政策来收集、处理、存储、使用和保护您的个人信息。
            </strong>
         </div>
         <div class="mb-12">
            <div class="mb-6">一. 适用范围</div>
            <div class="mb-8">
               本隐私政策适用于Uptick APP平台提供的所有服务（以下称“服务”或“我们的服务”），您访问Uptick APP平台及/或登陆客户端使用Uptick提供的服务，均适用本隐私政策。
               本政策所称“Uptick APP平台”包括但不限于Uptick APP客户端
            </div>
            <div class="mb-8">
               我们所有的服务均适用本隐私政策。此外，针对某些特定服务，我们还将制定特定隐私政策，以便更具体地说明我们在该特定服务中如何收集、处理、存储、使用和保护您的信息。该特定服务的隐私政策构成本隐私政策的一部分。如相关特定服务的隐私政策与本隐私政策有不一致之处，适用该特定服务的隐私政策。
            </div>
            <div class="mb-8">
               我们所有的服务均适用本隐私政策。此外，针对某些特定服务，我们还将制定特定隐私政策，以便更具体地说明我们在该特定服务中如何收集、处理、存储、使用和保护您的信息。该特定服务的隐私政策构成本隐私政策的一部分。<strong
                class="str"
            >如相关特定服务的隐私政策与本隐私政策有不一致之处，适用该特定服务的隐私政策。</strong
            >
            </div>
            <div class="mb-8">
               <strong class="str"
               >本政策不适用于通过我们的服务而接入的其他第三方服务（“其他第三方”，包括但不限于您的交易相对方、任何第三方网站以及第三方服务提供者）收集的信息。我们对前述第三方使用您个人信息的行为及后果不承担任何责任。</strong
               >
               请您注意，其他第三方可能有自己的隐私权保护政策；当您查看其他第三方创建的网页或使用其他第三方开发的应用程序时，可能会发现该网页或应用程序放置的Cookie或像素标签。这些Cookie或标签不受我们的控制，而且它们的使用不受本政策的约束。
            </div>
         </div>
         <div class="mb-12">
            <div class="mb-6">二. 我们如何收集您的个人信息</div>
            <div class="mb-8">
               我们收集信息是遵守法律法规，向您提供更好以及更个性化的服务，并努力提高您的用户体验。 您向我们提供下述信息或使用我们的服务时，即表示您同意我们按照本政策规定收集信息。
            </div>
            <div class="mb-8">
               为了遵守法律法规的要求，我们需要您授权部分信息，从而向您提供钱包相关基本功能，并提供更个性化、更便捷的服务。我们仅收集、使用必要的信息。
            </div>
            <div class="mb-8">
               基于您的明示授权，我们可能会获取您如下信息：设备ID信息（获取设备ID，为您推送资产变更通知）、相册（更新个人头像）、存储（缓存文字、图片等，使页面流量更流畅）、位置信息（推荐附近的活动）等，您有权拒绝或取消授权；
            </div>
            <div class="mb-8">
               请注意您在Uptick
               APP平台发布内容过程中所提交的任何文字、照片、视频等各种形式的信息，也可能会包含或者关联到您的个人信息。我们会确保您信息安全，未经您的同意，我们不会从第三方获取、共享或向其提供您的信息。
            </div>
            <div class="mb-16">
               您可以选择不提供某一或某些信息，但是这样可能使您无法使用我们的部分特色服务。
            </div>
            <div>
               请您理解，我们使用您提供的信息是为了回应您的要求，为您享受我们提供的各项服务提供便利，完善我们的网站以及与您进行信息沟通。
            </div>
         </div>
         <div class="mb-12">
            <div class="mb-6">三. 我们如何保护您个人信息的安全</div>
            <div class="mb-8">
               我们将采用各种合理的物理、电子和管理方面的安全措施来保护您的个人信息，使您的个人信息不会被泄漏、毁损或者丢失。我们会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力确保您的信息不被泄漏、毁损或丢失。
            </div>
         </div>
         <div class="mb-12">
            <div class="mb-6">四. 通知和修订</div>
            <div class="mb-8">
               为给您提供更好的服务，我们的业务将不时变化，本隐私政策也将随之调整。我们会
               通过在我们网站、移动端上发出更新版本或以其他方式提醒您相关内容的更新，也请您访问我们以便及时了解最新的隐私政策。在前述情况下，若您继续使用我们的 服务，即表示同意接受修改后的本政策并受之约束。
            </div>
         </div>
      </div>
   </div>
</template>

<script>
  export default {
    name: "privacy_zh",
    mounted() {
       window.eventBus.$on('LangChange', this.onLangChange);
    },
    beforeDestroy() {
      window.eventBus.$off("LangChange", this.onLangChange);
    },
    methods: {
       onLangChange() {
         if(this.$vuetify.lang.current == 'en'){
            this.$router.push({name:'Privacy'})
         }else if(this.$vuetify.lang.current == 'zh'){
            this.$router.push({name:'Privacyzh'})
         }else if(this.$vuetify.lang.current == 'ja'){
            this.$router.push({name:'Privacy'})
         }else if(this.$vuetify.lang.current == 'ko'){
            this.$router.push({name:'Privacy'})
         }else if(this.$vuetify.lang.current == 'pt'){
            this.$router.push({name:'Privacy'})
         }
       }
    }
  };
</script>

<style lang="scss" scoped>
   .contain {
      max-width: 806px;
      margin: 0 auto;

      .title {
         margin-top: 80px;
         margin-bottom: 50px;
        font-family:Helvetica;
         font-size: 55px !important;
         font-weight: bold;
         line-height: 60px;
         font-stretch: normal;
         letter-spacing: 0px;
         color: #270645;
         text-align: center;
      }

      .prag {
         .mb-12 {
            font-family:Helvetica;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;

            .str {
              font-family:Helvetica;
               font-style: italic;
               font-size: 16px;
               //   font-weight: bold;
               letter-spacing: 0px;
               color: #270645;
            }

            .mb-6 {
              font-family:Helvetica;
               font-size: 30px;
               font-weight: bold !important;
               font-stretch: normal;
               letter-spacing: 0px;
               color: #270645;
            }
         }
      }
   }
</style>
